@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap");
:root {
  /* --sidebar-flex: 1.5; */
  --condiv-flex: 6;
  --fontsize-p: 1.2rem;
  --fontsize-nav: 1.6rem;
  --typing-font: 3rem;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #374341;
}
/* body */
body {
  height: 100%;
  position: relative;
  background-color: #f4f5f9;
}

.App {
  margin-top: 100px;
  height: 100%;
  display: block;
  position: relative;
}

/* .condiv class is for the right side content container*/
.condiv {
  padding: 50px;
  padding-left: 100px;
  padding-right: 100px;
  flex: var(--condiv-flex);
}
/* navbar */
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  height: auto;
  background-color: #e29578;
  padding-left: 10px;
  padding-right: 10px;
  top: 0;
  position: sticky;
  z-index: 1;
  width: 100%;
}
.logo {
  font-size: 22px;
  font-weight: 600;
  font-size: larger;
  padding-left: 10px;
}

.nav-links {
  display: flex;
  width: 40%;
  justify-content: space-around;
  margin-left: auto;
}

.nav-links a {
  text-decoration: none;
  font-size: 16px;
}
.nav-links li {
  list-style: none;
}
.nav-links li :hover {
  text-decoration: underline;
}
.nav-links li:active {
  font-weight: bolder;
}
.burger {
  display: none;
}
.burger div {
  width: 25px;
  height: 3px;
  background-color: #333333;
  margin: 5px;
  transition: all 0.3s ease;
}

footer {
  display: flex;
  flex-direction: row;
  background-color: #ffddd2;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  padding-top: 10px;
  font-size: var(--fontsize-p);
}

/* social */
.condiv i {
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #9a9b9c;
}
.condiv i:hover {
  color: #e29578;
}
.center {
  display: flex;
  justify-content: center;
}
/* about */
#about {
  display: table;
  width: 100%;
}

.left-half {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 35%;
  padding: 1rem;
}
.left-half img {
  max-width: 100%;
  height: auto;
}

#right-half {
  display: table-cell;
  text-align: start;
  vertical-align: middle;
  width: 65%;
  padding: 1rem;
  text-align: start;
}
#right-half p {
  text-align: start;
}

.subtopic {
  margin: 10px 10px 30px 10px !important;
  color: #ff614b;
  text-align: center;
}
p {
  font-size: var(--fontsize-p);
  text-align: center;
}
h1,
h2,
h3,
h4,
p {
  margin: 10px;
}

/* home */
.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-size: 50% auto;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.contact {
  width: 100%;
  height: 100vh;
}
.home h1 {
  color: #ff614b;
  font-size: 40px;
}
.home p,
h5 {
  font-size: 16px;
}
#typingeffecttwo {
  color: #ff614b;
  font-weight: bold;
  font-size: 16px;
}

.arrow {
  text-align: center;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* techstack */
#skillsMain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#skillsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.techDiv {
  width: 320px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px;
  border: 1px solid #333333;
  margin: 6px;
}

.tech {
  width: 64px;
  max-height: 64px;
  display: flex;
  align-items: center;
  margin: 5px;
}

.techDivTitle {
  width: 100%;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  background-color: #ffddd2;
  padding: 5px;
}

.tech img {
  width: 64px;
  max-height: 70px;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #ffddd2;
  color: #333333;
  text-align: center;
  border-radius: 6px;

  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* techstack */
.projectsSection {
  padding: 0 1em;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.projectHR {
  text-align: center;
  width: 80%;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 1em 15%;
}

.project {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2em 3em;
}

#divider {
  background: #333;
  margin-top: 100px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  /* min-width: 50%; */
  margin: 1em;
}

.linksContainer {
  display: flex;
}

/* Visit and GitHub link effects */
.linksContainer a {
  position: relative;
  display: inline-block;
  outline: none;
  font-weight: 400;
  letter-spacing: 1px;
  color: #374341;
  text-shadow: #374341;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0.5em 0.5em;
  padding: 0 10px;
  height: 45px;
  line-height: 45px;
}

.linksContainer a::before,
.linksContainer a::after {
  position: absolute;
  width: 45px;
  height: 1.5px;
  background: #374341;
  content: "";
  opacity: 0.2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}

.linksContainer a::before {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
}

.linksContainer a::after {
  right: 0;
  bottom: -1px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.linksContainer a:hover::before,
.linksContainer a:hover::after,
.linksContainer a:focus::before,
.linksContainer a:focus::after {
  opacity: 1;
}

.linksContainer a:hover::before,
.linksContainer a:focus::before {
  left: 50%;
  -webkit-transform: rotate(0deg) translateX(-50%);
  -moz-transform: rotate(0deg) translateX(-50%);
  transform: rotate(0deg) translateX(-50%);
}

.linksContainer a:hover::after,
.linksContainer a:focus::after {
  right: 50%;
  -webkit-transform: rotate(0deg) translateX(50%);
  -moz-transform: rotate(0deg) translateX(50%);
  transform: rotate(0deg) translateX(50%);
}

.nameAndLinks p {
  margin: 0;
}

.linksContainer a:hover {
  text-decoration: none;
  color: #374341;
  text-shadow: 0 1px 1px #374341;
  margin: 0.5em 0.5em;
}

.projectname {
  margin: 0;
  padding: 0;
}

.projectname h2 {
  margin-bottom: 0.25em;
}

.excerpt {
  margin-bottom: 0;
  text-align: start;
}

.mockupAndLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mockupContainer {
  position: relative;
  margin: 0 1em 1em 1em;
}

.mockupContainer:hover .mockupOverlay {
  opacity: 1;
}

.mockup {
  display: block;
  margin: 0;
}

.mockupOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.7s ease;
  background: #fff;
  display: flex;
  justify-content: center;
}

.techStack {
  text-align: center;
  padding: 0 0 0.5em 0;
  position: absolute;
  align-self: center;
  justify-content: center;
}

.techStack img {
  width: 6em;
  max-width: 100%;
  vertical-align: middle;
  margin: 0.75em 0.75em;
}

/* services */
#services {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#services p {
  font-size: 14px;
}
.card {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  height: 250px;
  width: 250px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card:hover i {
  color: #e29578;
}

.card h4:hover {
  color: #ff614b;
}

.container {
  padding: 2px 16px;
}
.nav {
  display: none;
}

@media only screen and (max-width: 768px) {
  #about {
    display: flex;
    flex-direction: column;
  }
  .left-half {
    /* display: flex; */
    width: 100%;
  }
  .left-half img {
    width: 80%;
    height: 80%;
  }
  .left-half p {
    font-size: 14px;
    font-style: italic;
    width: 100%;
    text-align: center;
  }
  #right-half {
    width: 100%;
  }
  #right-half p {
    width: 100%;
    padding: 5px;
    text-align: start;
    font-size: 14px;
  }
  .condiv {
    padding: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .subtopic {
    margin: 10px 0px 30px 0px !important;
  }
  #services {
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .card {
    height: 100%;
    width: 100%;
    padding-top: 10px;
  }
  .description {
    width: 80%;
  }
  .mockupAndLinks {
    width: 100%;
  }
  .project {
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .excerpt {
    font-size: 14px;
  }
  #contact {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  #contact p {
    font-size: 14px;
  }
  /* mobile nav */
  .logo {
    margin-right: auto;
  }
  body {
    overflow-x: hidden;
    width: 50vh;
  }
  .nav-links {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: #e29578;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0%;
    padding-bottom: 10vh;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    z-index: 1;
  }
  .nav-links li {
    opacity: 0;
  }
  .burger {
    display: block;
    cursor: pointer;
  }
}

.nav-active {
  transform: translateX(0%);
  display: flex;
  width: 50%;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
